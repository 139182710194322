import posthog from 'posthog-js';
import { postHogAPIKey, postHogHostUrl } from '../utilities/config';
import { Helpers } from '../utilities/helpers';

let inactivityTimer;
const INACTIVITY_TIMEOUT = 1 * 60 * 1000;

const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(() => {
    stopSessionRecording();
  }, INACTIVITY_TIMEOUT);
};

const stopSessionRecording = () => {
  posthog.stopSessionRecording();
};

const startSessionRecording = () => {
  posthog.startSessionRecording();
};

const resumeSessionRecording = () => {
  if (!posthog.sessionRecordingStarted) {
    startSessionRecording();
  }
};

const initializePostHog = async () => {
  posthog.init(postHogAPIKey, {
    api_host: postHogHostUrl,
    session_recording: {
      maskInputFn: (text, inputElement) => {
        if (inputElement?.classList?.contains('sensitive-data-field')) {
          return '*'.repeat(text.length);
        }

        return text;
      },
    },
  });

  const userId = await Helpers.getAuthUserId();

  if (userId) {
    posthog.identify(userId);
  } else {
    posthog.reset()
  }

  // Set up event listeners after initializing PostHog
  document.addEventListener('mousemove', resetInactivityTimer);
  document.addEventListener('keypress', resetInactivityTimer);
  document.addEventListener('click', resetInactivityTimer);

  document.addEventListener('mousemove', resumeSessionRecording);
  document.addEventListener('keypress', resumeSessionRecording);
  document.addEventListener('click', resumeSessionRecording);

  // Start the inactivity timer
  resetInactivityTimer();
};

const monitorUserActivity = () => {
  const events = ['mousemove', 'keypress', 'click'];

  events.forEach((event) => {
    document.addEventListener(event, resetInactivityTimer);
  });

  // Start the inactivity timer when monitoring begins
  resetInactivityTimer();
};

const cleanupActivityMonitoring = () => {
  const events = ['mousemove', 'keypress', 'click'];

  events.forEach((event) => {
    document.removeEventListener(event, resetInactivityTimer);
  });

  clearTimeout(inactivityTimer);
};

const cleanupInactivityListeners = () => {
  document.removeEventListener('mousemove', resetInactivityTimer);
  document.removeEventListener('keypress', resetInactivityTimer);
  document.removeEventListener('click', resetInactivityTimer);

  document.removeEventListener('mousemove', resumeSessionRecording);
  document.removeEventListener('keypress', resumeSessionRecording);
  document.removeEventListener('click', resumeSessionRecording);

  clearTimeout(inactivityTimer);
  posthog.reset()
};

// Cleanup listeners when no longer needed
window.addEventListener('beforeunload', cleanupInactivityListeners);

export {
  initializePostHog,
  monitorUserActivity,
  stopSessionRecording,
  startSessionRecording,
  cleanupActivityMonitoring
}